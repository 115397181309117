import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hops"
    }}>{`Hops`}</h1>
    <h2 {...{
      "id": "ibu-calculation"
    }}>{`IBU Calculation`}</h2>
    <p>{`Hops flowers contain alpha and beta resins and essential oils within the lupulin glands of the flower.
Alpha resins are not very soluble in water, therefore, need to be boiled to extract the proper bittering from the alpha acid(at least 60 Minutes).`}</p>
    <p><img alt="Calculation" src="https://static1.squarespace.com/static/57b6c555d1758ee9c0fa12cb/t/57ce06cee3df282738f29879/1473119952824/Equation+1.jpg?format=2500w" /></p>
    <ul>
      <li parentName="ul">{`W is the weight of the hop addition,`}</li>
      <li parentName="ul">{`U% is the utilization which comes from a table that factors in the gravity of the boil and duration of the boil,`}</li>
      <li parentName="ul">{`A% is the alpha acid percentage of the hop variety,`}</li>
      <li parentName="ul">{`V is the volume of the final wort in liters, `}</li>
      <li parentName="ul">{`Cgravity is a correction factor to be used only when the wort in the boil has a specific gravity higher than 1.050.`}</li>
    </ul>
    <h2 {...{
      "id": "chemical-composition"
    }}>{`Chemical composition`}</h2>
    <ul>
      <li parentName="ul">{`Alpha acids `}</li>
    </ul>
    <h2 {...{
      "id": "list"
    }}>{`List`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`alpha`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`bata`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Perle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Citra`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7.8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3-4.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Amarillo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7.7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6-7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cascade`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7.8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.6-7.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tradition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "utilization-table"
    }}>{`Utilization Table`}</h2>
    <p>{`Boil	Original Gravity	`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Time(min)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.030`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.040`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.050`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.060`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.070`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.080`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.090`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.100`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.110`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.120`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.130`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.034`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.031`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.029`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.026`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.024`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.022`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.020`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.018`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.017`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.015`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.014`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.065`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.059`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.054`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.049`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.045`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.041`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.038`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.035`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.032`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.029`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.026`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.092`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.084`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.077`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.070`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.064`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.059`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.054`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.049`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.045`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.041`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.037`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.116`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.106`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.097`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.088`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.081`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.074`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.068`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.062`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.056`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.052`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.047`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.137`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.125`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.114`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.105`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.096`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.087`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.080`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.073`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.067`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.061`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.056`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.156`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.142`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.130`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.119`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.109`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.099`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.091`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.083`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.076`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.069`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.063`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.173`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.158`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.144`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.132`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.110`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.101`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.092`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.084`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.077`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.070`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.187`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.171`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.157`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.143`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.131`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.109`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.091`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.083`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.076`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.201`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.183`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.168`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.153`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.140`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.128`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.117`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.107`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.098`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.089`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.082`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.212`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.194`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.177`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.162`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.148`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.135`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.124`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.113`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.103`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.094`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.086`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.223`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.203`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.186`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.170`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.155`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.142`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.130`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.119`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.108`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.099`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.091`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.232`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.212`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.194`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.177`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.162`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.148`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.135`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.124`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.113`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.103`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.094`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.240`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.219`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.183`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.167`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.153`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.140`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.128`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.117`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.107`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.098`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.247`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.226`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.206`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.189`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.172`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.158`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.144`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.132`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.110`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.101`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.253`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.232`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.212`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.194`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.177`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.162`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.148`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.135`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.123`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.113`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.103`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.259`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.237`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.216`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.198`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.181`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.165`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.151`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.138`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.126`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.115`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.105`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`51`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.264`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.241`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.221`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.202`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.184`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.169`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.154`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.141`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.129`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.118`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.108`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`54`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.269`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.246`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.224`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.205`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.188`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.171`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.157`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.143`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.131`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.109`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`57`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.273`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.249`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.228`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.208`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.190`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.174`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.159`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.145`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.133`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.121`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.111`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`60`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.276`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.252`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.231`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.211`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.193`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.176`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.161`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.147`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.135`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.123`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.112`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`70`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.285`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.261`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.238`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.218`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.199`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.182`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.166`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.152`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.139`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.127`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.116`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.291`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.266`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.243`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.222`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.203`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.186`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.170`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.155`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.142`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.130`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.119`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`90`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.295`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.270`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.247`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.226`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.206`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.188`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.172`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.157`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.144`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.132`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.120`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.301`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.275`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.252`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.230`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.210`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.192`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.176`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.161`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.147`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.134`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.123`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      